:root {
  /* dark */
  --black: #24242d;
  --gray: #bfc0c0;
  --white: #d4d4d4;
  --orange: #ef8354;
  --dark: #4f5d75;
  --accent: #545970;
  --error: #c42741;
  --primary: #b9375e;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Prevent adjustments of font size after orientation changes in iOS. */
  -webkit-text-size-adjust: 100%; /* 2 */
  touch-action: manipulation;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

#telegram-login-PlantIn_expert_help_test_bot {
  position: absolute;
  top: 32px;
  right: 32px;
}

body {
  color: var(--white);
  margin: 0;
  font-size: 1rem;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-weight: 400;
  line-height: 1.6;
  /* overflow-x: hidden; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--black);
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #000000;
}

::selection {
  color: #fff;
  background-color: #b9375e73;
}

a {
  color: inherit;
  text-decoration: none;
}

img,
button {
  outline: none;
  border: none;
}

img {
  border: none;
  max-width: 100%;
  user-select: none;
  vertical-align: middle;
}

button {
  border: 0;
  padding: 0;
  background: none;
  user-select: none;
  text-decoration: none;
  font-size: inherit;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

input,
textarea {
  border: none;
  font-family: inherit;
  -webkit-appearance: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
  padding: 0;
}

strong,
b {
  font-weight: 700;
}

ul {
  list-style: none;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

@media screen and (max-width: 600px) {
  body {
    overflow-x: hidden;
  }
}
