abbr[title] {
  text-decoration: none;
}

.react-calendar.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.react-calendar__month-view__weekdays {
  margin-bottom: 1rem;
}

.react-calendar__month-view__weekdays__weekday {
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #91a0b6;
  text-align: center;
}

.react-calendar__navigation {
  margin-bottom: 1rem;
}

.react-calendar__navigation__arrow {
  font-size: 1rem;
  font-weight: bold;
  flex-basis: 50px;
  outline: none;
  cursor: pointer;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__label {
  flex-grow: 1;
}

.react-calendar__navigation__label__labelText--from {
  font-weight: 500;
  font-size: 16px;
  color: #91a0b6;
  letter-spacing: 0.12px;
}

.react-calendar__tile {
  font-weight: 500;
  font-size: 14px;
  height: 32px;
  margin-bottom: 10px;
  outline: none;
  color: #fff;
  cursor: pointer;
}

.react-calendar__tile:disabled {
  color: #7e8b9e;
}

.react-calendar__tile--range {
  background: #cceee5;
  color: #04bf94;
}

.react-calendar__tile--active {
  background: #912d4b;
  border-radius: 8px;
  color: #fff;
  border: 2px solid #b9385e;
}

.react-calendar.disabled .react-calendar__tile--active {
  background: #b1b7be;
  border: 2px solid #b1b7be;
}

.react-calendar__navigation__arrow {
  color: #91a0b6;
}
